.carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-image {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
}